@import '../../../assets/styles/styles.scss';

.services{
    padding: 200px 8% 100px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #090909;

    .section__intro{
        @include home-sub-1;
        max-width: 560px;
        margin-bottom: 92px;
        font-weight: bold;
    }
    .summary{
        .summary_wrapper{

            .services__container{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: space-between;
                margin-bottom: 2rem;
    
            }
        }

    }
    .more__btn{
        transition: 1s ease-in;
        display: flex;
        gap: 86px;
        align-items: center;
        height: fit-content;
        color: #ffffff;
        width: max-content;
        max-width: 361px;
        transition: 0.5s ease-in-out;
        margin-left: auto;

        h2{
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
        }
        .chevron-right{
            transition: 0.5s ease-in-out;
            svg{
                fill: white;
            }

        }
    }
    .more__btn:hover{
        padding-left: 40px;
        .chevron-right{
            transform: translateX(50px);
        }
    }

}

// @media screen and (max-width: $large) {
//     .services{

//         .summary{
//             flex-direction: column;
//             .services__container{
//                 display: flex;
//                 flex-direction: row;
//                 flex-wrap: wrap;
//                 justify-content: space-around;
//                 align-items: center;
//                 width: 100%;
//             }
//         }
//     }
// }

@media screen and (max-width: $medium) {
    .services{
        padding: 30px 0 30px;
        .section__intro{
            margin-bottom: 20px;
            padding: 0 8%;
        }
        .summary{
            height: 480px;
            overflow: hidden;
            .summary_wrapper{
                // flex-direction: column;
                overflow-x: scroll;
                height: 600px;
                
                .services__container{
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    width: max-content;
                    margin-left: 8%;
                    margin-right: 8%;
                    // align-items: center;
                }
            }
        }
        .more__btn{
            gap: 20px;
            // padding-left: 50px;
            margin-left: 8%;
            h2{
                font-size: 14px;
                margin-top: -5px;
                // max-width: 80px;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .services{
        .summary{
            height: 450px;
        }
    }
}