// colors
$brand-blue: #090F2D;
$brand-orange: #F87000;
$brand-orange-light: #FFD7B7;
$royal-blue: #0825B4;
$brand-white: #ffffff;
$brand-grey: rgba(9, 15, 45, 0.5);
$brand-yellow: #FFC700;

// letters
$letter-spacing-l: -0.02em;

// background
$main-background: #141414;
$background-with-gradient: linear-gradient(360deg, #FFFFFF 0%, rgba(250, 249, 249, 0.7) 100%);


// breakpoints
$small: 420px;
$mobile: 600px;
$medium: 750px;
$large: 1000px;
$max-width: 1800px;