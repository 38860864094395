@import "../../assets/styles/styles.scss";


.projects{
    @extend .container;
    
    .projects_title{
      @include home-sub-1;
      font-weight: bold;
      margin-bottom: 50px;
    }
    .projects_container{
      display: flex;
      flex-direction: column;
      gap: 50px;

      // .see-more{
      //     @extend .see-more-projects;
      // }
    }
}

.project-card{
    width: 100%;
    border-radius: 23px;
    overflow: hidden;
    height: fit-content;
    position: relative;

    .main-content{
        width: 100%;
        img {
            width: 100%;
        }
    }
    .details{
        position: absolute;
        // z-index: 2;
        bottom: 10%;
        width: 100%;
        left: 0;
        // background: red;
        .details-wrapper{
            width: 80%;
            margin: 0 auto;
            
            .details-container{
                padding: 0 4%;
                display: flex;
                gap: 3px;
                width: fit-content;
                align-items: center;
                justify-content: space-between;
                transition: 1s ease-in-out;
                border-radius: 14px;
                height: 92px;
                backdrop-filter: blur(10px);

                h3{
                    color: #ffffff;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: bolder;
                    min-width: max-content;
                }

                .details-others{
                    width: 0px;
                    transition: 1s ease-in-out;
                    display: flex;
                    justify-content: space-between;
                    gap: 3px;
                    flex-wrap: nowrap;
                    overflow: hidden;

                    p{
                        font-size: 24px;
                        line-height: 40px;
                        color: #929292;
                        min-width: max-content;
                    }
                    figure{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }

        }
    }


}

@media screen and (min-width: $medium) {

    .project-card:hover{
        .details{
            .details-wrapper{
                .details-container{
                    justify-content: space-between;
                    width: 100%;
                    .details-others{
                        width: 50%;
                    }
                }
            }
        }
    }
}
  

.gr8container{
    display: flex;
    gap: 2rem;
    width: 100%;
    background: #131313;
    border-radius: 23px;
    padding: 3rem 8%;
    justify-content: space-around;

    .desktop{
        width: 70%;
    }
    .mobile{
        width: 30%;
    }
}

@media screen and (max-width: $medium){
    .projects{
        .projects_title{
            margin-bottom: 20px;
          }
        .projects_container{
            gap: 20px;
        }
    }
    .project-card{
        border-radius: 8px;

        .details{
            bottom: 10%;
            width: 100%;
            left: 0;
            .details-wrapper{
                width: 83%;
                
                .details-container{
                    padding: 0 8%;
                    gap: 3px;
                    border-radius: 6px;
                    height: 50px;
                    width: 100%;
    
                    h3{
                        font-size: 13px;
                        line-height: 20px;
                    }
                    .details-others{
                        width: 60%;
                        p{
                            font-size: 13px;
                            line-height: 20px;
                        }
                    }
                    // figure{
                    //     display: none;
                    //     transition: 1s ease-in-out;
                    // }
                }
    
            }
        }
    }

    .gr8container{
        gap: 2rem;
        width: 100%;
        border-radius: 23px;
        padding: 3rem 8%;
        justify-content: space-around;
        height: 400px;
    
        .desktop{
            width: 70%;
            max-height: 100%;
            img{
                width: 100%;
            }
        }
        .mobile{
            width: 30%;
            max-height: 100%;
            img{
                width: 100%;
            }
        }
    }
}