@import '../../assets/styles/styles.scss';

.carousel_container{
    @extend .container;
    overflow: hidden;
    .title{
        @include home-sub-1;
        margin-bottom: 4rem;
        font-weight: bold;
    }

    .carousel{
        width: 100%;
        position: relative;
        height: 660px;
    }

    .carousel_btn{
        display: flex;
        gap: 2rem;
        margin-bottom: 4rem;

        .btn{
            background: red;
            background: rgba(19, 19, 19, 1);
            // padding: 20px 20px 10px;
            width: 75px;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            transition: 1s ease;
            img{
                opacity: 0.5;
            }
        }
        .next_btn,.next_btn_active{
            @extend .btn;
        }
        .prev_btn,.prev_btn_active{
            @extend .btn;
            transform: rotate(180deg);
        }
        .prev_btn_active,.next_btn_active{
            background: rgba(41, 40, 40, 1);
            img{
                opacity: 1;
            }
        }
    }
}


.testimony_card{
    background: #0F0E0E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 33px;
    padding: 68px 70px;
    border-radius: 20px;
    height: 600px;
    width: 70%;
    // transition: 0.5s ease-in;
    transition: z-index 1s 1s;
    transition: right 2s ease-in-out;
    color: #ffffff;
    position: absolute;

    .testimony{
        font-size: 24px;
        line-height: 164.5%;
    }
    .testifier{
        display: flex;
        gap: 25px;
        align-items: center;
        // height: max-content;

        .icon{
            width: 74px;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .person{
            .name{
                font-size: 20.2px;
                font-weight: bolder;
                text-transform: capitalize;
            }
            .role{
                font-size: 15px;
            }
        }
    }
}
.testimony_card_1{
    @extend .testimony_card;
    right: 30%;
    z-index: 4;
    background: #1b1b1b;
    @media screen and (max-width: $medium) {
        right: 8%;
    }
    @media screen and (max-width: $mobile){
        right: 6%;
    }
}
.testimony_card_2{
    @extend .testimony_card;
    right: 20%;
    z-index: 3;
    @media screen and (max-width: $medium) {
        right: 6%;
    }
    @media screen and (max-width: $mobile){
        right: 4%;
    }

}
.testimony_card_3{
    @extend .testimony_card;
    right: 10%;
    z-index: 2;
    @media screen and (max-width: $medium) {
        right: 3%;
    }

    @media screen and (max-width: $mobile) {
        right: 2%;
    }
}
.testimony_card_4 {
    @extend .testimony_card;
    right: 0;
    z-index: 1;
}

@media screen and (max-width: $large) {
    .testimony_card{
        .testimony{
            font-size: 19px;
        }
        .testifier{
            .person{
                .name{
                    font-size: 17px;
                }
                .role{
                    font-size: 15px;
                }
            }
        }
    }
}


@media screen and (max-width: $medium){
    .carousel_container{
        .title{
            margin-bottom: 1.5rem;
        }
        .carousel{
            height: 420px;
        }
    }
    .testimony_card{
        gap: 9px;
        padding: 40px 30px;
        border-radius: 19px;
        height: 400px;
        width: 98%;
        align-items: center;
        .quotation_mark{
            display: none;
        }
        .testimony{
            font-size: 14px;
            line-height: 145%;
            text-align: center;
        }
        .testifier{
            gap: 16px;
            align-items: center;
            height: fit-content;
    
            .icon{
                width: 49px;
                height: 49px;
            }
            .person{
                .name{
                    font-size: 14px;
                }
                .role{
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .carousel_container{
        .carousel_btn{
            margin-bottom: 2rem;
            .btn{
                width: 37px;
                height: 26px;
                img{
                    width: 13px;
                }
            }
        }
    }
}