@import '../../../assets/styles/styles.scss';

.fullcards{
    width: 100%;
    .service-krent{
        margin-bottom: 120px;
    }

    .service{
        display: flex;
        justify-content: space-between;
        margin-bottom: 120px;

        .image{
            width: 52%;
            max-width: 650px;
            overflow: hidden;
            border-radius: 20px;

            .img{
                width: 100%;
            }
        }
        .text{
            width: 46%;
            padding: 10px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-wrap: break-word;

            .repeat{
                max-width: 410px;
                color: $brand-blue;
                margin-bottom: 30px;
                
            }

            h2{
                @extend .repeat;
                font-size: 60px;
                line-height: 96%;
                font-weight: 700;
                letter-spacing: $letter-spacing-l;
            }
            p{
                @extend .repeat;
                font-size: 20px;
                color: $brand-grey;
                line-height: 133%;
            }
            .learn-more{
                color: $royal-blue;
                display: flex;
                gap: 12px;
                font-size: 18px;

                img{
                    margin-top: 2px;
                    transition: 0.5s ease-in-out;
                }
            }
            .learn-more:hover{
                img{
                    transform: translateX(20px);
                }
            }
        }
    }
    .brand{
        @extend .service;
        .image{
            position: relative;
            height: 687px;
            perspective: 1px;
            z-index: 2;

            .image__wrapper{
                width: max-content;
                position: absolute;
                z-index: 1;
                overflow: visible;
                top: -720px;
                left: -1200px;
                margin-left: -50%;
                transform: scale(0.4);
                animation: scale-brand-image 8s infinite 1s linear;

                img{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: $large) {
    .fullcards{
        .service{
            flex-direction: column;
            gap: 30px;
            margin-bottom: 80px;

            .text{
                width: 100%;
                padding-left: 0;

                .repeat{
                    max-width: 600px;
                }
                p{
                    max-width: 100%;
                }
            }
            .image{
                width: 100%;
                order: 1;
                max-width: 100%;
                max-height: 600px;
                margin: 0 auto;
                .img{
                    max-height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .fullcards{
        .service-krent{
            margin-bottom: 50px;
        }
        .service{
            margin-bottom: 20px;

            .text{
                h2{
                    font-size: 50px;
                }
            }
        }
        @media screen and (max-width: $mobile){
            .service{
                gap: 20px;
                .text{
                    padding-top: 1px;
                    h2{
                        font-size: 36px;
                        margin-bottom: 20px;
                    }
                    p{
                        font-size: 12px;
                        margin-bottom: 20px;
                    }
                }
                .image{
                    max-height: 450px;
                }
            }
        }
    }
}

@keyframes scale-brand-image {
    0%, 100%{
        top: -720px;
        left: -1200px;
        margin-left: -50%;
        transform: scale(0.4);
    }
    25%, 75% {
        top: -760px;
        left: -1000px;
        margin-left: -50%;
        transform: scale(0.35);
    }
    50%{
        left: -1050px;
    }

}