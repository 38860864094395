@import 'variables';

@mixin size-limit {
    width: 100vw;
    max-width: $max-width;
    margin: 0 auto;
}

@mixin heading-xxl {
    font-size: 103px;
    font-weight: 700;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: $brand-blue;

    @media screen and (max-width: $mobile) {
        font-size: 50px;
    }
}

@mixin heading-xl {
    font-size: 96px;
    font-weight: 700;
    line-height: 96%;
    letter-spacing: -0.01em;
    color: $brand-blue;

    @media screen and (max-width: $mobile) {
        font-size: 48px;
    }
}

@mixin heading-m {
    font-size: 24px;
    letter-spacing: 0.02em;
    line-height: 121.5%;
    font-weight: 400;
    color: $brand-blue;

    @media screen and (max-width: $mobile) {
        font-size: 14px;
    }
}

@mixin card-sm {
    background: linear-gradient(214.34deg, #FFFFFF 5.05%, rgba(255, 255, 255, 0) 78.9%);
    border-radius: 21px;
    padding: 50px;
    color: $brand-blue;
    transition: 1s ease-in;

    h2{
        font-size: 58px;
        font-weight: 700;
        line-height: 100%;
    }
    p{
        font-size: 20px;
        line-height: 121%;
        font-weight: 400;
    }

    &:hover{
        background: $royal-blue;
        color: $brand-white;
    }

    @media screen and (max-width: $mobile){
        padding: 20.7px;
        border-radius: 8.7px;

        h2{
            font-size: 24px;
        }
        p{
            font-size: 8.29px;
        }
    }
}

@mixin card-lg {
    border-radius: 24px;
    background: #171717;
    padding: 80px 60px;
    transition: 1s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .description{

        h2{
            font-size: 28px;
            font-weight: 700;
            color: #e1e1e1;
            line-height: 100%;
        }
        p{
            font-size: 14px;
            color: #ffffff;
            line-height: 144%;
        }
        &:hover{
            box-shadow: 163px 187px 147px rgba(57, 57, 57, 0.04);
            background: transparent;
    
        }
        // @media screen and (max-width: $mobile){
        //     padding: 50px;
        //     border-radius: 19.8px;
        //     background: $brand-white !important;
        //     box-shadow: 163px 187px 147px rgba(57, 57, 57, 0.04);
        //     transition: none;
    
        //     h2{
        //         font-size: 23px;
        //     }
        //     p{
        //         font-size: 14px;
        //     }
    
          
        // }
    }
    @media screen and (max-width: $mobile) {
        padding: 60px 55px 45px;
        max-width: 330px;

        .description{
            h2{
                font-size: 24px;
            }
        }
    }
    
}

@mixin home-sub-1 {
    font-size: 28px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #ffffff;

    @media screen and (max-width: $mobile) {
        font-size: 24px;
    }
}
