@import '../../assets/styles/styles.scss';
$mediumScreen: 750px;

.topbar{
    @include size-limit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    height: 102px;
    padding: 10px 8%;
    background: #272727;
    // border-bottom: 1px solid #FFFFFF;
    backdrop-filter: blur(20px);
    position: fixed;
    z-index: 1000;

    .navbar{
        display: flex;
        flex-grow: 2;
        justify-content: space-around;
        max-width: 500px;
        gap: 16px;

        .link {
            overflow: hidden;
            margin-top: 5px;

            hr {
                border: 1px solid #6B6B6B;
                border-radius: 2px;
            }
        }
        .nav__link{
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nav__link-title{
                color: #ffffff;
            }
            .nav__link--dash{
                @extend .link;
                transition: ease-in-out 0.7s;
                visibility: hidden;
                width: 30px;
                height: fit-content;
                
            }
        }
        .nav__link:hover{

            .nav__link--dash{
                visibility: visible;
                width: 16px !important;
            }
            
        }
        .nav__link--active{
            color: #ffffff;
            .nav__link--dash{
                visibility: visible;
                width: 16px ;
                @extend .link;
            }
        }
        .contact-nav{
            display: none;
        }
    }

    .buttons{
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;

        .contact--active{
            button{
                background-color: #6B6B6A !important;
            }
        }
        .hamburger{
            display: none;
        }
    }
}

@media screen and (max-width: $mediumScreen) {
    .topbar{
        // position: relative;

        .logo{
            width: 76px;
            svg{
                width: 100%;
            }
        }

        .navbar{
            flex-direction: column;
            transition: 0.5s ease-in-out;
            position: absolute;
            right: 0;
            top: 102px;
            z-index: 1000;
            background: #272727;
            border-radius: 7px;
            padding: 20px;

            .nav__link, .nav__link--active{
                padding: 8px 10px;
            }
            .contact-nav{
                display: inline-block;
            }
        }
        .hide-nav{
            transform: translateX(200%);
        }
        .buttons{
            .contact,.contact--active{
                display: none;
            }
            .hamburger{
                display: block;
                svg{
                    transform: scale(1.4);
                }
            }
        }
    }
    @media screen and (max-width: $mobile) {
        .topbar{
            height: 67px;
            .navbar{
                top: 67px;
            }
            .buttons{
                .hamburger{
                    svg{
                        transform: scale(1);
                    }
                }
            }
        }
    }
}




@media screen and (min-width: $max-width) {
    .topbar{
        left: 50%;
        margin-left: -900px;
    }
}