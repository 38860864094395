.general-container {
  // height: 102px;
  max-width: 1800px;
  margin: 0 auto;
  padding: 100px 8%;
  background-color: #191919;
 

  .btx-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    color: #ffffff;
    .container-build {
      font-size: 65px;
      font-weight: 700;
      .build {
        color: #00FFB2;;
      }
    }
    .btn-first {
      background: #131313;
      border-radius: 10px;
      color: white;
      font-weight: 700;
      padding-top: 25px;
      padding-bottom: 25px;
      height:max-content;
      text-align: center;
      width:13%;
    }
    .btn-first:hover{
      background-color: #1e1d1d;
    }
  }

  .webxiel-foot {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10%;
    justify-content:flex-end;
    color: #ffffff;

    .logo {
      width: 30%;
      margin-right: 100px;
      display: flex;
      flex-direction: column;
      gap: 21px;

      .logo_intro{
        font-size: 12.89px;
        line-height: 117%;
      }

      .logo_contact{
        display: flex;
        gap: 8.6px;
        align-items: flex-start;
        // margin-top: 21px;

        svg{
          width: 12px;
        }
        .logo_contact_content{
          display: flex;
          flex-direction: column;
          margin-top: -5px;
          p>a{
            font-size: 12.892px;
            font-weight: 700;
          }
        }
      }
      // p {
      //   margin-top: 15px;
      // }
    }
    .table-list {
      width: 65%;
      grid-template-columns: 1fr 1fr 1fr;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
   
      .table-child {
        // margin-bottom: 40px;

        p {
          font-weight: 700;
          line-height: 94%;
          margin-bottom: 2rem;
        }
        ul{
          display: flex;
          flex-direction: column;
          gap: 2rem;

          li a{
            text-transform: capitalize;
          }
        }
      }
      
    }
  }

  hr {
    background-color: #e8e8e8;
    border: 0 none;
    color: #e8e8e8;
    height: 1px;
  }

  .copy-container {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: left;
  }
}

@media screen and (max-width: 815px) {
  .general-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 60px 8% 10px;
   
    .btx-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-top: 10px;
      .container-build {
        font-size: 35px;
        font-weight: 700;
        .build {
          color: #00FFB2;
        }
      }
      .btn-first {
        background: #131313;
        border-radius: 10px;
        color: white;
        font-weight: 700;
        margin-top: 20px;
        font-size: 14px;
        @media screen and (max-width: 450px){
          font-size: 12px;
        }
        padding: 18px;
        height:fit-content;
        text-align: center;
        width:max-content;
        white-space: nowrap;
      }
      .btn-first:hover{
        background-color: #1e1d1d;
      }
    }
  
    .webxiel-foot {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 80px;
      justify-content:center;
      gap: 40px;
      .logo {
        width: 100%;
        margin-right: 100px;
        // line-height: 22px;
        // p {
        //   margin-top: 25px;
        // }
      }
      .table-list {
        width: 100%;
        margin-bottom: 40px;
        // margin-top: 58px;
        // line-height: 117%;
        // display: grid;
        // column-gap: 40px;
        // row-gap: 29px;
        // grid-template-columns: 1fr 1fr 1fr;
        // justify-content: center;
        .table-child{
          p{
            font-size: 12px;
          }
        }
        
        ul{
          display: flex;
          flex-direction: column;
          row-gap: 29px;
          margin-bottom: 29px;
        }
        
        a{
          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
        
        .table-child p {
          font-weight: 700;
          
        }
        
      }
    }
    @media screen and (min-width: 600px) {
      .webxiel-foot{
        .logo{
          .logo_intro{
            font-size: 15px;
          }
          .logo_contact{
            gap: 12px;
    
            svg{
              width: 15px;
            }
            .logo_contact_content{
              margin-top: -5px;
              p>a{
                font-size: 15px;
              }
            }
          }
        }
        .table-list{
          .table-child{
            p{
              font-size: 15px;
            }
          }
        }

      }
      .copy-container{
        font-size: 14px;
        text-align: center;
      }
    }

  }
}

@media screen and (min-width: 815px) {
  .general-container {
    max-width: 1800px;
    padding: 100px 8%;
   
    .btx-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-top: 10px;
      .container-build {
        font-size: 60px;
        font-weight: 700;
        .build {
          color: #989898;
        }
      }
      .btn-first {
        background: #131313;
        border-radius: 10px;
        font-weight: 700;
        color: white;
        padding: 24px;
        height:fit-content;
        text-align: center;
        width: max-content;
        white-space: nowrap;
        transition: 0.4s ease-in;
      }
      .btn-first:hover{
        background-color: #1e1d1d;
      }
    }
    .webxiel-foot{
      margin-bottom: 40px;
      border-radius: 20px;
      // background: #FAFAFA;
      padding: 81px 0;
      display: flex;
      gap: 89px;
      justify-content: center;

      .logo{
        margin-right: 0;
        width: 40%;
        .logo_intro{
          font-size: 16px;
        }
        .logo_contact{
          gap: 16px;
  
          svg{
            width: 15px;
          }
          .logo_contact_content{
            margin-top: -5px;
            p>a{
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
      .table-list{
        margin-top: 14px;
      }
    }
    .copy-container{
      margin-bottom: 20px;
      height: 40px;
      font-size: 16px;
      text-align: center;
    }
  
  }
}