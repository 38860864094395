// style={{ backgroundImage: `url(${image})`, width: "100%", height: "100vh", position: "relative"}}
@import '../../assets/styles/styles.scss';


.picture{
  @extend .container;

  .group {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #c2b280;
    border-radius: 15px;
    // margin-top: 100px;
    position: relative;
    color: white;
    height: fit-content;
  
    .youtube_video{
      aspect-ratio: 16/9;
      width: 100%;
      border-radius: 12px;
    }
  }
}

@media screen and (max-width: $small) {
  .picture{
    padding: 30px 9%;
  }
}
