@import "../../assets/styles/styles.scss";


.about{
  @include size-limit;
  background-color: $main-background;

  .vision_mission{
    @extend .container;
    color: #ffffff;

    .vision_mission_wrapper{
      display: flex;
      flex-direction: column;
      gap: 12rem;

      .intro{
        width: 80%;
        max-width: 1023px;
        border-radius: 21px;
        padding: 6% 9%;
        background: #282828;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-self: flex-end;

        h3{
          font-size: 54px;
          font-weight: bold;
          letter-spacing: -0.27px;
          line-height: 94%;
          max-width: 675px;

        }
        p{
          font-size: 24px;
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .cards{
        display: flex;
        gap: 50px;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        max-width: 1023px;
        border-radius: 21px;
        padding: 6% 9%;
        background: #282828;

        .text{
          display: flex;
          flex-direction: column;
          gap: 20px;

          h3{
            font-size: 48px;
            font-weight: bold;
            // letter-spacing: -0.27px;
            // line-height: 94%;
            max-width: 675px;
          }
          p{
            font-size: 20px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
      .vision{
        @extend .cards;
        .text{
          text-align: right;
        }
      }
      .mission{
        @extend .cards;
        align-self: flex-end;
      }
    }

  }

}

@media screen and (max-width: $large) {
  .about{
    .vision_mission{
      .vision_mission_wrapper{
        gap: 7rem;
        .intro{
          width: 100%;
        }
        .cards{
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $medium) {
  .about{
    .vision_mission{
      padding-top: 30px;
      .vision_mission_wrapper{
        gap: 4rem;
        @media screen and (max-width: $small){
          gap: 2rem;
        }
        .intro{
          padding: 8% 9%;
          border-radius: 15px;
          h3{
            @include home-sub-1;
          }
          p{
            font-size: 14px;
          }
        }
        .cards{
          width: 100%;
          padding: 9% 9%;
          flex-direction: column;
          .text{
            gap: 13px;
            h3{
              @include home-sub-1;
            }
            p{
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        .vision{
          .icon{
            width: 100%;
            padding: 3% 3%;
            img{
              width: 100%;
            }
          }
          .text{
            text-align: left;
          }
        }
        .mission{
          .text{
            order: 2;
          }
          .icon{
            width: 60%;
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}


