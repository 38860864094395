@font-face {
  font-family: 'Sk-Modernist';
  src: url('./assets/fonts/Sk-Modernist-Bold.otf') format('otf'),
        url('./assets/fonts/Sk-Modernist-Mono.otf') format('otf'),
        url('./assets/fonts/Sk-Modernist-Regular.otf') format('otf');
}


@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap');



body {
  margin: 0;
  font-family: 'Wix Madefor Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  color: inherit;
  cursor: pointer;
  border-width: 0;
  background-color: transparent;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}