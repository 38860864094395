@import '../../assets/styles/styles.scss';

.card-small{
    @include card-sm;
    width: fit-content;
    max-width: 221px;
    
    .title {
        margin-bottom: 10px
    }

    @media screen and (max-width: $mobile){
        max-width: 92px;
    }
}

.card-large{
    @include card-lg;
    max-width: 361px;
    min-height: 455px;
    h2{
        overflow-wrap: anywhere;
        color: #e1e1e1;
    }
    p{
        color: #ffffff;
    }

    h2, p {
        max-width: 241px;
        margin-bottom: 19px;
    }
    .icon{
        margin-bottom: 10px;
    }
    // .learn-more{
    //     margin-top: 72px;
    //     margin-bottom: 0;
    //     a{
    //         display: flex;
    //         align-items: center;
    //         gap: 16px;
    //         .arrow-right{
    //             padding-top: 6px;
    //             transition: 0.5s ease-in;
    //         }
    //     }
    // }
    // .learn-more:hover{
    //     a{
    //         .arrow-right{
    //             transform: translateX(15px);
    //         }
    //     }
    // }

    @media screen and (max-width: $mobile){
        min-height: 424px;
        h2, p, .icon{
            margin-bottom: 17px;
        }
    }
}