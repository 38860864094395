@import '../../assets/styles/styles.scss';

.contact-us{
    background: #141414;
    @include size-limit;
    padding-bottom: 5rem;

    .contact{
        @extend .container;
        padding-top: 180px;
        // background-color: $main-background;
        display: flex;
        gap: 3rem;
        justify-content: space-between;
    
        .header{
            margin-bottom: 100px;
            width: 40%;
            color: #ffffff;
    
            .header__title{
                font-size: 103px;
                font-weight: 700;
                // color: $brand-blue;
                line-height: 92%;
                margin-bottom: 31px;
                max-width: 299px;
            }
            .header__text{
                font-size: 24px;
                line-height: 104%;
                // color: $brand-blue;
                margin-bottom: 20px;
                max-width: 299px;
                a{
                    text-decoration: underline;
                }
            }
        }
        .form-wrapper{
            width: 55%;
        }
    }

    .offices{
        @extend .container;
        .offices_container{
            border-radius: 28px;
            color: #ffffff;
            background: linear-gradient(to right, #1b1b1b, #141414);
            padding: 4rem 3rem 5rem;

            .offices_header{
                @include home-sub-1;
                margin-bottom: 3rem;
                font-weight: bold;
            }
            .offices_wrapper{
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .office{
                    display: flex;
                    flex-direction: column;
                    gap: .6rem;

                    .type{
                        font-weight: bold;
                        font-size: 1.3rem;
                    }
                    .tel{
                        display: flex;
                        gap: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $medium){
    .contact-us{
        .contact{
            flex-direction: column;
            gap: 0;
            .header{
                width: 100%;
                margin-bottom: 50px;
                .header__title{
                    font-size: 70px;
                }
            }
            .form-wrapper{
                width: 100%;
            }
        }
        @media screen and (max-width: $mobile) {
            .contact{
                padding-top: 120px;
                
                .header{
                    margin-bottom: 30px;
    
                    .header__title{
                        font-size: 40px;
                        line-height: 36px;
                        margin-bottom: 10px;
                    }
                    .header__text{
                        font-size: 12px;
                        line-height: 14px;
                        padding-left: 3px;
                    }
                }
            }
        }
        .offices {
            .offices_container {
                border-radius: 12px;
                padding: 3rem 1rem 3rem;

                .offices_wrapper {

                    .office {
                        display: flex;
                        flex-direction: column;
                        gap: .6rem;

                        .type {
                            font-weight: bold;
                            font-size: 1.3rem;
                        }

                        .tel {
                            display: flex;
                            gap: 1rem;
                        }
                        .location,.tel{
                            font-size: 0.85rem;
                        }
                    }
                }
            }
        }
    }
    
}