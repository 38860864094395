@import '../../../assets/styles/styles.scss';

.form{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 100px;
    gap: 52px;

    .form_container{
        display: flex;
        flex-direction: column;
        gap: 30px;

    }
    .form-control{
        display: flex;
        flex-direction: column;
        font-size: 24px;

        label{
            margin-bottom: 20px;
            color: #FFFFFF;
        }
        input{
            padding: 20px 30px;
            // border: 1px solid #D2D2D2;
            border: none;
            font-size: 1.2rem;
            border-radius: 15px;
            background-color: #282828;
            // color: $brand-blue;
            color: #c1c1c1;
        }
        textarea{
            height: 400px;
            // border: 1px solid #D2D2D2;
            border: none;
            border-radius: 15px;
            font-size: 1rem;
            overflow-y: scroll;
            color: #c1c1c1;
            background-color: #282828;
            padding: 40px;
        }
        button{
            background: #00FFB2;
            padding: 1.2rem 2rem;
            border-radius: 0.6rem;
            font-size: 1rem;
            color: #000000;
            width: fit-content;
            font-family: inherit;
            font-weight: 700;
            line-height: 148%;
        }
        .btn__processing{
            background: #FFD7B7;
        }
    }

    .err-message{
        font-style: italic;
        font-size: 14px;
        color: #ff3333;
    }
}

@media screen and (max-width: $medium){
    .form{
        .form_container{
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }
}

@media screen and (max-width: $mobile) {
    .form{
        gap: 31px;
        margin-bottom: 10px;

        .form-control{
            font-size: 12px;

            label{
                margin-bottom: 16px;
            }
            input{
                padding: 18px 24px;
                font-size: 0.8rem;
                border-radius: 8.83721px;
            }
            textarea{
                height: 298px;
                padding: 24px;
                font-size: 0.8rem;
                border-radius: 8.84px;
            }
            button{
                font-size: 0.8rem;
                padding: 1rem 1.7rem;
                border-radius: 10px;
            }
        }
    }
}