@import '../../assets/styles/styles.scss';

.services{
    @include size-limit;
    background-color: $main-background;

    .container__cards {
        @extend .container;
        .fullcards{
            width: 100%;
            .service-krent{
                margin-bottom: 120px;
            }
        
            .service{
                display: flex;
                justify-content: space-between;
                margin-bottom: 120px;
        
                .image{
                    width: 52%;
                    max-width: 650px;
                    overflow: hidden;
                    border-radius: 20px;
        
                    img{
                        width: 100%;
                    }
                }
                .text{
                    width: 46%;
                    padding: 10px 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow-wrap: break-word;
        
                    .repeat{
                        max-width: 410px;
                        color: #ffffff;
                        margin-bottom: 30px;
                        
                    }
        
                    h2{
                        @extend .repeat;
                        font-size: 40px;
                        line-height: 96%;
                        font-weight: 700;
                        letter-spacing: $letter-spacing-l;
                    }
                    p{
                        @extend .repeat;
                        font-size: 20px;
                        color: #737373;
                        line-height: 133%;
                    }
                    .learn-more{
                        color: #ffffff;
                        display: flex;
                        gap: 12px;
                        font-size: 18px;
        
                        img{
                            margin-top: 2px;
                            transition: 0.5s ease-in-out;
                        }
                    }
                    .learn-more:hover{
                        img{
                            transform: translateX(20px);
                        }
                    }
                }
            }
        }
        
        .largeCards__container{
            display: flex;
            // justify-content: space-between;
            flex-direction: column;
            margin-top: 160px;
            justify-content: center;
            .largeCards__services_title{
                display: none;
            }

            .largeCards{
                width: 100%;
                display: flex;
                gap: 2rem;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
    .results{
        @extend .container;
    }

}

@media screen and (max-width: $large){
    .services{
        .container__cards{
            .fullcards{
                .service{
                    flex-direction: column;
                    max-height: 1000px !important;
                    gap: 30px;
                    margin-bottom: 80px;
                    width: 100%;

                    .text{
                        width: 100%;
                        max-width: 100%;
                    }
                    .image{
                        width: 100%;
                        max-width: 100%;
                        order: 2;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .services{
        .container__cards{
            .fullcards{
                .service{
                    flex-direction: column;
                    max-height: 1000px !important;
                    gap: 30px;
                    width: 100%;

                    .text{
                        width: 100%;
                        max-width: 100%;
                        h2{
                            font-size: 50px;
                            padding-top: 20px;
                        }
                        @media screen and (max-width: $mobile){
                            h2{
                                font-size: 36px !important;
                                margin-bottom: 20px;
                            }
                            p{
                                font-size: 12px;
                                margin-bottom: 12px;
                            }
                        }
                        .learn-more{
                            font-size: 12.3px;
                        }
                    }
                    .image{
                        width: 100%;
                        max-width: 100%;
                        order: 2;
                    }
                }
            }
            .largeCards__container{
                .largeCards__services_title{
                    display: flex;
                    flex-direction: column;
                    gap: 1px;
                    @include home-sub-1;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile){
    .services{
        .container__cards{
            padding-bottom: 5px;
        }
        .container__products{
            margin-top: 0;
            gap: 20px;

            .section-title{
                font-size: 24px;
                margin-bottom: 3px;
            }
        }
    }
}

@keyframes translate-arrow-down {
    0%, 25%, 100% {
        transform: translateY(0px);
        transform: scaleY(1);
        
    }
    50%, 75% {
        transform: translateY(90px);
        transform: scaleY(2);
        // top: 50px;
    }
}