@import 'variables';
@import 'mixins';


.container{
    padding: 80px 8%;
    max-width: 1800px;
    margin: 0 auto;

    @media screen and (max-width: $mobile){
        padding: 50px 9%;
    }
}

.see-more-projects{
    align-self: flex-end;
    border: 1px solid #E9E9E9;
    border-radius: 13px;
    padding: 30px 40px;
    margin-top: 20px;
    width: fit-content;
    color: $brand-blue;
    font-size: 22px;
    display: flex;
    gap: 36px;
    cursor: pointer;

    .arrow-right{
        padding-top: 2px;
        transition: 0.5s ease-in-out;
    }

    @media screen and (max-width: $mobile) {
        font-size: 10.63px;
        padding: 12px 15px;
        margin-top: 10px;
        gap: 20px;

        .arrow-right{
            padding-top: 0;
        }
    }
}
.see-more:hover {
    .arrow-right{
        transform: translateX(20px);
    }
}