@import '../../../assets/styles/styles.scss';

.result-factors{
    // @extend .container;
    padding: 80px 2%;
    // background: linear-gradient(214.34deg, #FFFFFF 5.05%, rgba(255, 255, 255, 0) 78.9%);
    border-radius: 22px;
    background: #282828;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-title{
        font-size: 57.96px;
        // text-transform: capitalize;
        line-height: 100%;
        font-weight: 700;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .factors{
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: center;
        align-items: center;
        max-width: 1160px;
        margin: 0 auto;

        .factor{
            display: flex;
            gap: 29px;
            border-radius: 19px;
            padding: 45px;
            background-color: #383838;
    
            .text{
                max-width: 310px;
    
                .title{
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 16px;
                    color: #EDEDED;
                    line-height: 100%;
                }
                .details{
                    color: rgba(255,255,255, 0.5);
                    line-height: 150%;
                }
            }
        }
        .webxiel-logo{
            padding: 41px;
            border-radius: 16px;
            background-color: $brand-white;
        }
    }

}

@media screen and (max-width: $medium){
    .result-factors{
        .factors{
            flex-direction: column;
            align-items: center;
        }

        @media screen and (max-width: $mobile){
            .section-title{
                font-size: 36px;
            }
            .factors{
                gap: 20px;

                .factor{
                    gap: 20px;
                    border-radius: 13.6px;
                    padding: 30px;

                    .text{
                        .title{
                            font-size: 17.21px;
                        }
                        .details{
                            font-size: 11.5px;
                        }
                    }
                    .icon{
                        width: 35px;

                        svg{
                            width: 100%;
                        }
                    }
                }
                .webxiel-logo{
                    padding: 21px;
                }
            }
        }
    }
}