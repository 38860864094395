@import '../../assets/styles/styles.scss';

.btn {
    color: #ffffff;
    font-weight: 700;
    padding: 16px 20px;
    background-color: #6B6B6B;
    border-radius: 10px;
    height: max-content;
    transition: .5s ease-in-out;
}
.btn:hover{
    background-color: #6B6B6f;
}

.btn-lg{
    padding: 24px 30px;
    border-radius: 15px;
}

@media only screen and (max-width: $mobile) {
    .btn{
        font-size: 12px;
        padding: 12px 12px;
        line-height: 11.3px;
        border-radius: 8px;
    }
    .btn-lg{
        padding: 16px 20px;
        border-radius: 11px;
    }
}