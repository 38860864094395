@import '../../assets/styles/styles.scss';

.hero{
    padding: 180px 8% 100px;
    width: 100%;
    height: 950px;
    display: flex;
    justify-content: space-between;
    // background: #141414;
    background: linear-gradient(340deg, #141414 0%, #000000 40%,  #141414 60%, #000000 90%);
    overflow: hidden;
   
    .wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: relative;
        .title{
            font-size: 150px;
            font-weight: normal;
            line-height: 77.234%;
            letter-spacing: -0.61788rem;
            background: linear-gradient(0deg, #EBEBEB 14.12%, rgba(255, 255, 255, 0.00) 155.12%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: flex;
            flex-direction: column;
            margin-bottom: 100px;
            span{
                margin-bottom: 2rem;
            }
        }
        .go_down{
            position: absolute;
            right: 2%;
            bottom: 10%;
            svg{
                animation: rotate 6s linear 0s infinite normal none;
                transition: 8s ease;
            }
        }
        .go_down-hide{
            display: none;
        }
    }
}

@keyframes rotate {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(30deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(-30deg);}
    100% {transform: rotate(0deg);}
}
@media screen and (max-width: $medium){
    .hero{
        height: 580px;
        padding: 120px 8% 30px;

        .wrapper{

            .title{
                font-size: 70px;
                line-height: 94%;
                letter-spacing: -0.045em;
                span{
                    margin-bottom: 0;
                }
            }
            .go_down{
                width: 82px;
                height: 82px;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}