@import '../../assets/styles/styles.scss';
$w1200px: 1200px;

.krent{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 39px;
    max-height: 746px;
    overflow: hidden;
    background-color: $brand-white;
    border-radius: 23px;
    
    .text{
        width: 40%;
        padding-top: 50px;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .wrapper{
            max-width: 271px;

            .repeat{
                // max-width: 410px;
                color: #ffffff;
                margin-bottom: 30px;
                
            }
            
            .title{
                @extend .repeat;
                font-size: 30px;
                font-weight: 700;
                // color: $royal-blue;
                line-height: 110%;
                margin-bottom: 8px;
            }
            .details{
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 20px;
            }
            .learn-more{
                color: $brand-grey;
                display: flex;
                gap: 36px;
                cursor: pointer;
                
                .arrow-right{
                    padding-top: 2px;
                    transition: 0.5s ease-in-out;
                }
            }
            .learn-more:hover{
                .arrow-right{
                    transform: translateX(20px);
                }
            }
        }
    }
    
    .figures{
        position: relative;
        width: 60%;
        overflow: hidden;
        border-bottom-right-radius: 23px;
        z-index: 4;

        .profile, .homescreen{
            width: 55%;
            position: absolute;
            right: 0;

            img{
                width: 100%;
            }
        }
        .cards{
            width: 55%;
            position: relative;

            .cards__animate{
                width: 75%;
                position: absolute;
                top: 80px;
                left: 50%;
                margin-left: -38%;
                animation: translate-cards 5s infinite 1s linear;
            }

            img{
                width: 100%;
            }
        }
        .profile{
            z-index: 2;
            left: 50%;
            margin-left: -13%;
            max-height: 707px;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .homescreen{
            z-index: 3;
            right: 50%;
            margin-right: -75%;
        }
    }
}

.krent-large{
    @extend .krent;
    max-height: 700px;
    background-color: $main-background;

    .text{
        width: 46%;
        align-items: flex-start;
        justify-content: center;
        .wrapper{
            max-width: 406px;
            .repeat{
                max-width: 410px;
                color: #ffffff;
                margin-bottom: 30px;
                
            }
            .title{
                // @extend .repeat;
                // font-size: 75px !important;
                // line-height: 96%;
                // // margin-bottom: 30px;
                // // color: $brand-blue !important;
                // letter-spacing: $letter-spacing-l;
                @extend .repeat;
                font-size: 40px;
                line-height: 96%;
                font-weight: 700;
                letter-spacing: $letter-spacing-l;
            }
            .details{
                // font-size: 20px;
                // color: $brand-grey;
                // line-height: 132%;
                // margin-bottom: 30px;
                @extend .repeat;
                font-size: 20px;
                color: #737373;
                line-height: 133%;
            }
            // .learn-more{
            //     color: $royal-blue;
            //     font-size: 18px;
            // }
            .learn-more{
                color: #ffffff;
                display: flex;
                gap: 12px;
                font-size: 18px;

                img{
                    margin-top: 2px;
                    transition: 0.5s ease-in-out;
                }
            }
            .learn-more:hover{
                img{
                    transform: translateX(20px);
                }
            }
        }
    }
    .figures{
        width: 52%;
        border-radius: 32px;
        overflow: hidden;
    }
}
@media screen and (max-width: $w1200px){
    .krent{
        max-height: 600px !important;
        

        .figures{
            .profile, .homescreen{
                height: 100%;
            }
        }
        @media screen and (max-width: $large) {
            max-height: 450px !important;
        }
    }
    .krent-large{
        @media screen and (max-width: $large) {
            flex-direction: column;
            max-height: 1000px !important;
            gap: 30px;

            .text{
                width: 100%;
                max-width: 100%;
                .wrapper{
                    max-width: 100%;
                }
            }
            .figures{
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: $medium){
    .krent{
        max-height: 600px !important;
        .text{
            display: none;
        }
        .figures{
            width: 100% !important;
            .cards{
                .cards__animate{
                    top: 35px;
                    width: 70%;
                }
            }
        }
        
        @media screen and (max-width: $mobile){
            max-height: 420px !important;
        }
        @media screen and (max-width: $small){
            max-height: 360px !important;
        }
    }
    .krent-large{
        padding-top: 5px;
        padding-bottom: 20px;
        max-height: 900px !important;
        gap: 20px;
        @media screen and (max-width: $mobile){
            max-height: 620px !important;

            .figures{
                border-radius: 23px;
            }
        }
        @media screen and (max-width: $small){
            max-height: 600px !important;
        }

        .text{
            display: block;
            padding-top: 20px;
            .wrapper{
                .title{
                    font-size: 50px !important;
                    padding-top: 20px;
                }
                @media screen and (max-width: $mobile){
                    .title{
                        font-size: 36px !important;
                        margin-bottom: 20px;
                    }
                    .details{
                        font-size: 12px;
                        margin-bottom: 12px;
                    }
                }
                .learn-more{
                    font-size: 12.3px;
                }
            }
        }
    }
}


@keyframes translate-cards {
    0%, 100% {
        transform: translateY(0);
    }
    50%{
        transform: translateY(40px);
    }
}

