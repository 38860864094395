@import '../../../assets/styles/styles.scss';


.about{
    border-radius: 28px;
    overflow: hidden;
    background: linear-gradient(340deg, #3b3b3b 0%, #1b1b1b 70%,  #343434 90%);
    padding-top: 80px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .details{
        max-width: 500px;
        font-size: 48px;
        letter-spacing: -1px;
        line-height: 100%;
        font-weight: bold;
    }
    .about-btn{
        font-size: 20px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.9);
        line-height: 100%;
        border: none;
        outline: none;
        background: #343434;
        border-radius: 11px;
        padding: 22px 26px;
        // margin-top: 30px;
        transition: 0.5s ease-in;
    }
    .team_guys{
        width: 100%;
        img{
            width: 100%;
        }
    }
}

@media screen and (max-width: $small){
    .about{
        border-radius: 15px;
        padding-top: 40px;
        gap: 0;
    
        .details{
            max-width: 80%;
            font-size: 32px;
            letter-spacing: -1px;
            margin-bottom: -30px;
        }
        .about-btn{
            display: none;
        }
        .team_guys{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}