@import '../../assets/styles/styles.scss';

.home{
    @include size-limit;
    background-color: $main-background;

    .aboutwebxiel{
        @extend .container;
        padding-top: 0;
    }
    .easy_life{
        @extend .container;
        .easy-wrapper{
            border-radius: 29px;
            overflow: hidden;
            position: relative;

            img{
                width: 100%;
            }
            p{
                position: absolute;
                color: #fff;
                // z-index: 2;
                bottom: 5%;
                left: 10%;
                max-width: 520px;
                font-size: 48px;
                font-weight: bold;
                letter-spacing: -1px;
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .home{
        .aboutwebxiel{
            padding-left: 4%;
            padding-right: 4%;
        }
        .easy_life{
            display: none;
        }
    }
}